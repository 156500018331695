define("discourse/plugins/discourse-events/discourse/components/events-category-chooser", ["exports", "select-kit/components/category-chooser"], function (_exports, _categoryChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _categoryChooser.default.extend({
    classNames: ["events-category-chooser"],
    selectKitOptions: {
      allowUncategorized: false
    },
    categoriesByScope() {
      return this._super().filter(category => category.events_enabled);
    }
  });
});